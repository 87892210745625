<template>
  <WeContainer card="">
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-user"></i>
        <span>Üye Raporları</span>
      </h5>
    </WeCard>
    <WeTableSearch
      v-on:search="onSearch"
      v-on:clear="onFilterClear"
      v-bind:show-icon="false"
      header-class="bg-white text-dark"
      header-text-class="text-dark h6"
      v-bind:show-on-load="true"
    >
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- Created At -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Üyelik Tarihi"
                placeholder="Üyelik Tarihi"
                v-bind:date-range="true"
                v-model="filter.dates"
                selected-shortcut="thisMonth"
              />
            </div>
            <!-- Created At -->
          </div>
          <!-- Left -->

          <!-- Right -->
          <div class="col-12 col-lg-6">
            <!-- Durum -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                name="is_active"
                label="Durum"
                placeholder="Durum"
                v-bind:clearable="true"
                v-bind:option-prop="statusOptions"
                v-model="filter.is_active"
              />
            </div>
            <!-- Durum -->
          </div>
          <!-- Right -->
        </div>
      </div>
    </WeTableSearch>
    <WeCard>
      <div class="position-relative" style="min-height: 250px" v-if="loading">
        <WeLoading />
      </div>
      <div v-else v-bind:id="printConfig.id">
        <span v-print="printConfig" ref="printButton"> </span>
        <div class="text-center print">
          <h5 class="font-weight-bold">{{ getFileName }}</h5>
        </div>
        <WeTable
          v-bind:index="false"
          v-bind:columns="columns"
          v-bind:data="list"
          v-bind:actions="[]"
          v-bind:exportables="exportables"
          v-on:on-export="onExport"
          v-bind:paginatable="paginatable"
          v-bind:pageDetails="pageDetails"
          v-bind:limitable="limitable"
          v-bind:searchable="searchable"
        />
      </div>
    </WeCard>
  </WeContainer>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Member",
  data() {
    return {
      loading: false,
      modalLoading: true,
      showModal: false,
      paginatable: true,
      pageDetails: true,
      limitable: true,
      searchable: true,
      list: [],
      columns: [
        { name: "current_code", th: "Hesap Kodu", type: "string" },
        { name: "full_name", th: "Ad Soyad", type: "string" },
        { name: "gsm", th: "Telefon", type: "string" },
        { name: "city", th: "İl", type: "string" },
        { name: "district", th: "İlçe", type: "string" },
        { name: "neighborhood", th: "Mahalle", type: "string" },
        { name: "is_active", th: "Durum", type: "boolean_disabled" },
      ],
      filter: {
        dates: {
          start: null,
          end: null,
        },
        is_active: null,
      },
      statusOptions: [
        {
          id: "1",
          name: "Aktif",
        },
        {
          id: "0",
          name: "Pasif",
        },
      ],
      exportables: ["excel", "pdf"],
      printConfig: {
        id: "MemberReport",
        popTitle: "Üye Raporları",
      },
    };
  },
  methods: {
    ...mapActions("reports", ["makeSearch", "show"]),
    ...mapActions("tableExport", ["exportData"]),
    getFilter(copyDates = true) {
      let copy = helper.clone(this.filter);
      copy.dates = [];

      if (
        copyDates &&
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        Object.entries(this.filter.dates).forEach(([key, value]) => {
          if (value) {
            copy.dates.push(value);
          }
        });
      } else {
        delete copy.dates;
      }

      return copy;
    },
    onSearch() {
      this.loading = true;
      this.makeSearch({
        routeKey: "members",
        filter: this.getFilter(),
        onSuccess: (result) => {
          this.list = result.data.items;
          this.loading = false;
        },
      });
    },
    onFilterClear() {
      this.filter = {
        dates: {
          start: null,
          end: null,
        },
        is_active: null,
      };
      this.onSearch();
    },
    onExport(data) {
      if (this.list && this.list.length) {
        if (data.name !== "print") {
          let fileName = "Üye Raporları";

          if (
            this.filter.dates &&
            this.filter.dates.start &&
            this.filter.dates.end
          ) {
            let startDate = new Date(
              this.filter.dates.start
            ).toLocaleDateString("tr-TR");
            let endDate = new Date(this.filter.dates.end).toLocaleDateString(
              "tr-TR"
            );

            fileName = startDate + " - " + endDate + " - " + fileName;
          }

          this.exportData({
            route: "report/members/export",
            exportable: data,
            fileName: fileName,
            filter: {
              ...this.getFilter(),
              type: data.name,
            },
          });
        } else {
          this.setPrintableSettings(true);
          this.$refs.printButton.click();

          setTimeout(() => {
            this.setPrintableSettings(false);
          }, 100);
        }
      } else {
        this.$swal({
          title: "Dışarı aktarılacak veya Yazdırılacak veri mevcut değil",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    },
    setPrintableSettings(print = false) {
      this.paginatable = !print;
      this.pageDetails = !print;
      this.limitable = !print;
      this.searchable = !print;

      this.list.sort();
    },
  },
  computed: {
    getFileName() {
      const defaultName = "Üye Raporları";
      let result = "";

      if (
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        let startDate = this.filter.dates.start;
        let endDate = this.filter.dates.end;

        startDate = new Date(startDate).toLocaleDateString("tr-TR");
        endDate = new Date(endDate).toLocaleDateString("tr-TR");

        result = `${startDate} - ${endDate} ${defaultName}`;
      } else {
        result = defaultName;
      }

      return result;
    },
  },
  mounted() {
    this.onSearch();
  },
};
</script>